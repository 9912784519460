function formatPrice(x, float = 0) {
  x = Number(x);
  let price = Number.parseFloat(x).toFixed(float).replace(".", ",");

  return `${price}`;
}

function currencySymbol() {
  return process.env.VUE_APP_CURRENCY_SYMBOL || "$";
}

function getProductPrice(item) {
  const basicPrice = item.unitPrice * item.quantity;
  let totalOptionsPrice = 0;

  if (item.options && item.options[0]) {
    totalOptionsPrice = item.options.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.quantity * Number(currentValue.unitPrice),
      0
    );
  }
  return basicPrice + totalOptionsPrice;
}

export { formatPrice, currencySymbol, getProductPrice };
