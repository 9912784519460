export default {
  computed: {
    originName() {
      if (this.$route.meta.originName) {
        if (this.$store.state.menu.profile.isKioskModeEnabled) {
          console.log(`Kiosk mode DETECTED!, changing origin to BKiosk...`);
          return "BKIOSK";
        } else return this.$route.meta.originName;
      }
      if (typeof this.$route.meta === "function") {
        if (this.$route.meta().originName) {
          if (this.$store.state.menu.profile.isKioskModeEnabled) {
            console.log(`Kiosk mode DETECTED!, changing origin to BKiosk...`);
            return "BKIOSK";
          } else return this.$route.meta().originName;
        }
      }
      return this.$store.getters["menu/originName"];
    },

    currentProduct() {
      return this.$store.state.menu.products.find(
        (product) => product.uuid === this.$route.params.productId
      );
    },

    currentProductIsInOrigin() {
      if (
        this.currentProduct &&
        this.currentProduct.origins.find(
          (origin) => origin.name === this.originName
        )
      ) {
        return true;
      }
      return false;
    },

    currentProductOriginDetails() {
      if (this.currentProductIsInOrigin) {
        return this.currentProduct.origins.find(
          (origin) => origin.name === this.originName
        );
      }
      return false;
    },

    deliveryCartProductIdentifier() {
      return Number(this.$route.params.deliveryCartProductIdentifier);
    },

    currentAddedProduct() {
      if (
        !isNaN(this.deliveryCartProductIdentifier) &&
        this.$store.state.deliveryCart.products.length >=
          this.deliveryCartProductIdentifier + 1
      ) {
        return this.$store.state.deliveryCart.products[
          this.deliveryCartProductIdentifier
        ];
      }
      return undefined;
    },

    hasAddedProduct() {
      return this.currentAddedProduct ? true : false;
    },

    optionalQty() {
      //Esta funcion me actualiza las cantidades que restan a;adir de los adicionales y gustos y tambien me pone en true o false la variable que me dice si llego al tope
      var options = this.$store.state.currentProduct.options; //Me fijo en el producto seleccionado las opciones que tiene
      var optionals = 0;
      //Me fijo todas las opciones
      if (options.length > 0) {
        options.forEach((option) => {
          if (
            option.optionsGroupsName === "Adicionales" ||
            option.optionsGroupsName === "Gustos"
          ) {
            //Le sumo la cantidad
            optionals += option.quantity;
          }
        });
      }
      return optionals;
    },
  },

  methods: {
    initializeAddedProduct(shouldResetOptionals = false) {
      if (shouldResetOptionals) {
        this.$store.commit(
          "currentProduct/SET_CURRENT_PRODUCT_QUANTITY",
          this.currentAddedProduct?.quantity
            ? this.currentAddedProduct.quantity
            : 1
        );
      }

      if (
        (!this.$store.state.currentProduct.options[0] &&
          shouldResetOptionals) ||
        shouldResetOptionals
      ) {
        this.$store.commit(
          "currentProduct/SET_CURRENT_PRODUCT_OPTIONS",
          this.currentAddedProduct.options
        );
      }
    },
    hasAdditionalsOptions() {
      //Me dice si el producto tiene al menos un adicional para elegir
      var optionGroups = this.currentProduct?.optionsGroups;
      var flagAdditional = false;
      if (optionGroups)
        optionGroups.forEach((optionGroup) => {
          if (optionGroup.name === "Adicionales" && flagAdditional == false)
            flagAdditional = true;
        });
      return flagAdditional;
    },

    hasFlavorOptions() {
      //Me dice si el producto tiene al menos un gusto para elegir
      var optionGroups = this.currentProduct?.optionsGroups;
      var flagFlavor = false;
      if (optionGroups)
        optionGroups.forEach((optionGroup) => {
          if (optionGroup.name === "Gustos" && flagFlavor == false)
            flagFlavor = true;
        });
      return flagFlavor;
    },
  },
};
