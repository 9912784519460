export default {
  computed: {
    isStockValidationEnabled() {
      return this.$store.state.menu.profile.isStockValidationEnabled
        ? true
        : false;
    },
  },
  methods: {
    hasStock(stock) {
      if (!this.isStockValidationEnabled) {
        return true;
      }

      return Number(stock) > 0;
    },
    getStockQuantity(stock) {
      if (!this.isStockValidationEnabled) {
        return 999;
      }

      return Number(stock);
    },
  },
};
