var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{attrs:{"id":"menu","space":"0"}},[_c('v-container',{staticClass:"px-8 py-12"},[(_vm.currentProduct)?_c('div',[_c('v-slide-x-transition',{attrs:{"hide-on-leave":""}},[_c('base-outlined-block',[_c('v-col',{staticClass:"font-weight-bold text-subtitile-1 pl-0",attrs:{"cols":"7"}},[_c('div',[_vm._v("Unidades")]),(
                _vm.$store.state.currentProduct.quantity >=
                _vm.getStockQuantity(_vm.currentProduct.stock)
              )?_c('div',{staticClass:"text-subtitle-2 font-weight-light without-stock"},[_vm._v(" Stock máximo ")]):_vm._e()]),_c('v-col',{staticClass:"secondary rounded-xl px-5 chip",attrs:{"cols":"5"}},[_c('v-row',{staticClass:"py-2",attrs:{"justify":"space-between","align":"center"}},[_c('v-btn',{attrs:{"disabled":_vm.$store.state.currentProduct.quantity <= 1,"icon":"","fab":"","x-small":"","elevation":"0"},on:{"click":function($event){return _vm.$store.commit(
                    'currentProduct/SET_CURRENT_PRODUCT_QUANTITY',
                    --_vm.$store.state.currentProduct.quantity
                  )}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-minus")])],1),_c('div',{staticClass:"px-1 text-h6",class:_vm.$store.state.currentProduct.quantity <= 0
                    ? 'grey--text lighten-2 font-weight-regular'
                    : 'font-weight-medium'},[_vm._v(" "+_vm._s(_vm.$store.state.currentProduct.quantity)+" ")]),_c('v-btn',{attrs:{"disabled":Boolean(
                    _vm.$store.state.currentProduct &&
                      _vm.$store.state.currentProduct.options[0]
                  ) ||
                  _vm.$store.state.currentProduct.quantity >=
                    _vm.getStockQuantity(_vm.currentProduct.stock) ||
                  _vm.getMinimumFlavorLimit > 0,"color":"black","icon":"","fab":"","x-small":"","elevation":"0"},on:{"click":function($event){return _vm.$store.commit(
                    'currentProduct/SET_CURRENT_PRODUCT_QUANTITY',
                    ++_vm.$store.state.currentProduct.quantity
                  )}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)],1),(_vm.currentProduct && _vm.currentProduct.optionsGroups)?_c('div',_vm._l((_vm.currentProduct.optionsGroups),function(optionGroup,index){return _c('v-slide-x-transition',{key:index,attrs:{"hide-on-leave":""}},[_c('base-outlined-block',[_c('v-col',{staticClass:"font-weight-bold text-subtitile-1 pl-0",attrs:{"cols":"7"}},[_c('div',[_vm._v(_vm._s(optionGroup.name))]),_c('div',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.getMinimumFlavorLimit > 0 ? "(Obligatorio)" : "(Opcional)")+" ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"5"}},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"secondary rounded-xl px-6",attrs:{"disabled":_vm.$store.state.currentProduct.quantity !== 1,"elevation":"0","block":"","height":"48px"},on:{"click":function($event){return _vm.$router.push({
                    name: 'ProductExtras',
                    params: {
                      ..._vm.$route.params,
                      optionsGroupsIndex: index,
                    },
                    query: _vm.$route.query,
                  })}}},[_c('div',{staticClass:"text-center font-weight-bold text-subtitile-1 black--text"},[_vm._v(" Elegir ")])])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},_vm._l((_vm.getOptionsFromCurrentProduct.filter(
                  (x) => x.optionsGroupsName === optionGroup.name
                )),function(option,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(_vm.showOptionLine(option)))])])}),0)],1)],1)}),1):_vm._e(),(_vm.optionalLeft > 0 && _vm.getCurrentProductQuantity < 2)?_c('div',{staticClass:"text-start error--text"},[_vm._v(" Selecciona al menos "+_vm._s(_vm.optionalLeft)+" "+_vm._s(_vm.getOptionText)+" para continuar ")]):_vm._e()],1):_c('base-section',{attrs:{"space":"0"}},[(_vm.loadingContent)?_c('v-row',{staticClass:"py-12",attrs:{"justify":"center"}},[_c('base-progress-circular')],1):_c('v-row',{staticClass:"pt-12",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('v-icon',{staticClass:"mx-auto mb-6",attrs:{"size":"120","color":"primary lighten-2"}},[_vm._v(" mdi-cart-off ")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('base-subheading',{staticClass:"text-center text--disabled",attrs:{"title":"No se encontró ningún producto!"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }